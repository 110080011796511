<template>
  <!-- begin coming-soon -->
  <div class="coming-soon">
    <!-- begin coming-soon-header -->
    <div class="coming-soon-header">
      <div class="bg-cover"></div>
      <div class="brand">
        <img src="/assets/img/cover/dqh.png" /> <p> <b>东钱湖</b>企业安全码 2.0</p>
      </div>
      <div class="desc">
        Our website is almost there and it’s rebuilt from scratch! A lot of
        great new features <br />and improvements are coming.
      </div>
      <div class="timer">
        <div id="timer" class="is-countdown">
          <countdown :time="2 * 24 * 60 * 60 * 1000">
            <template slot-scope="props">
              <span class="countdown-row countdown-show4">
                <span class="countdown-section">
                  <span class="countdown-amount">{{ props.days }}</span>
                  <span class="countdown-period">Days</span>
                </span>
                <span class="countdown-section">
                  <span class="countdown-amount">{{ props.hours }}</span>
                  <span class="countdown-period">Hours</span>
                </span>
                <span class="countdown-section">
                  <span class="countdown-amount">{{ props.minutes }}</span>
                  <span class="countdown-period">Minutes</span>
                </span>
                <span class="countdown-section">
                  <span class="countdown-amount">{{ props.seconds }}</span>
                  <span class="countdown-period">Seconds</span>
                </span>
              </span>
            </template>
          </countdown>
        </div>
      </div>
    </div>
    <!-- end coming-soon-header -->
    <!-- begin coming-soon-content -->
    <div class="coming-soon-content">

      <div class="input-group">
        <input type="text" class="form-control" placeholder="Email Address" />
        <div class="input-group-append">
          <button type="button" class="btn btn-inverse">Notify Me</button>
        </div>
      </div>
<br>	<br>	<br>
      <p>
        0574-88498926 宁波市鄞州区东钱湖镇人民政府安监所     </p>
		
      <p>
        <a href="javascript:;" class="text-inverse"
          ><i class="fab fa-lg fa-fw fa-weibo"></i> 微博</a
        >
        <a href="javascript:;" class="text-inverse"
          ><i class="fab fa-weixin fa-lg fa-fw text-primary"></i> 公众号</a
        >
      </p>
    </div>
	<!-- <div id="footer" class="footer">
		&copy; 2022 Samruo.Com All Rights Reserved
	</div>	 -->
    <!-- end coming-soon-content -->
  </div>
  <!-- end coming-soon -->
</template>

<script>
export default {};
</script>

<style scoped>
.brand img {
  width: 70px;
  background-color: #fff;
  border-radius: 4px;
}
</style>